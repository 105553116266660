import { unref } from 'vue';
import { DateTime } from 'luxon';
import { Colors } from '@klaro/corejs/utils';
import { useInjectableArgs } from './InjectableArgs';
export const storyDimTexters = {
    // by code
    identifier: (value) => `N° ${value}`,
    createdAt: (value, locale) => DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toRelative(),
    updatedAt: (value, locale) => DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toRelative(),
    // by datatype
    ComputedDate: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    Date: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    RecentlyCreated: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    RecentlyUpdated: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    DueDate: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    InStateSince: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATE_FULL, { locale }),
    DateTime: (value, locale) => value === null ? '[none]' : DateTime.fromISO(value, { zone: 'Europe/Brussels', locale }).toLocaleString(DateTime.DATETIME_SHORT, { locale }),
    ComputedInteger: (value, locale) => value === null ? '[none]' : new Intl.NumberFormat(locale).format(value),
    Integer: (value, locale) => value === null ? '[none]' : new Intl.NumberFormat(locale).format(value),
    CustomOrder: (value, locale) => value === null ? '[none]' : new Intl.NumberFormat(locale).format(value),
    Like: (value, locale) => value === null ? '0' : new Intl.NumberFormat(locale).format(value),
    StoryBinaryCount: (value) => value || '0',
    Text: (value) => value === null ? '[none]' : value,
};
export const texter = (dimension, value, locale) => {
    const dimTexter = storyDimTexters[dimension.code] || storyDimTexters[dimension.datatype] || function (value) {
        return (value && value.label) || '[none]';
    };
    return dimTexter(value, locale);
};
export const useStoryDimensionValue = (args) => {
    const { pickRequired } = useInjectableArgs({
        story: undefined,
        boardState: undefined,
        dimension: undefined,
        globalContext: undefined,
    }, args);
    const getBackColor = (story, usesRaw, dimension, value) => {
        const dim = unref(dimension);
        if (usesRaw && dim.colored) {
            const matching = dim.firstMatchingValueOf(unref(story));
            return (matching && matching.color) || 'transparent';
        }
        else {
            return (value && value.color) || 'transparent';
        }
    };
    const getViewValues = (locale) => {
        const { story, dimension, boardState, globalContext, } = pickRequired(['boardState', 'dimension', 'globalContext', 'story']);
        const displayDimensionLabels = boardState.board.displayDimensionLabels;
        const usesRaw = !!(storyDimTexters[dimension.code] || storyDimTexters[dimension.datatype]);
        const rawValue = dimension.getStoryRawValue(story);
        let values = boardState.board.getDimensionValuesOn(dimension, story, globalContext) || [];
        values = (usesRaw ? [rawValue] : values);
        return values.map((value) => {
            const backColor = getBackColor(story, usesRaw, dimension, value);
            const borderColor = backColor === 'transparent' || backColor === '#ffffff' ? '#bdbdbd' : backColor;
            const textColor = Colors.cardTextColor(backColor);
            return {
                backColor: backColor,
                borderColor: borderColor,
                textColor: textColor,
                isColored: borderColor !== '#bdbdbd',
                text: texter(dimension, value, locale),
                style: `background-color: ${backColor}; border-color: ${borderColor}; color: ${textColor};`,
                tooltip: displayDimensionLabels ? '' : dimension.label,
            };
        });
    };
    return {
        getViewValues,
        getBackColor,
    };
};
